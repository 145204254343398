import Api from '@/api/index'

export function queryStakeList(data) {
  return Api.check(
      '/api/member/stake/order/list',
      'post',
      data
  )
}

export function queryStakeInfoV2() {
  return Api.check(
      '/api/member/v2/get/stake/interest',
      'post'
  )
}

export function withdrawInterestV2(data) {
  return Api.check(
      '/api/member/v2/withdraw/interest',
      'post',
      data
  )
}

export function queryStakeListV2(data) {
  return Api.check(
      '/api/member/v2/stake/order/list',
      'post',
      data
  )
}

export function redeemV2(data) {
  return Api.check(
      '/api/member/v2/redeem',
      'post',
      data
  )
}

