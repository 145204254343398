<template>
    <div class="root">
        <headers></headers>
        <div class="get-lay max-width-all">
            <div class="left">
                <h1>{{ $t('lp.title') }}</h1>
                <p>{{ $t('lp.desc') }}</p>
            </div>
            <img src="../../static/images/banner.png" />
        </div>
        <!-- 奖励记录 -->
        <div class="record-title max-width-all">
            <span>
                {{ $t('lp.record') }} 2.0
            </span>
            <div @click="goLp()">
                <img src="../../static/images/right.png" />
                <span>{{ $t('lp.back') }}</span>
            </div>
        </div>
        <!-- 质押列表 -->
        <div class="list">
            <div class="menu max-width-all">
                <span @click="getMenu(1)" :class="menuIndex==1?'get-menu':''"><span class="pc">DEFI</span> AI 0 – USDT</span>
                <span @click="getMenu(2)" :class="menuIndex==2?'get-menu':''"><span class="pc">DEFI</span> AI 30 – USDT</span>
                <span @click="getMenu(3)" :class="menuIndex==3?'get-menu':''"><span class="pc">DEFI</span> AI 90 – USDT</span>
            </div>
            <div class="content" v-for="(item, index) in stakes.list" :key="index">
                <div class="id">
                    <span style="font-weight: bold">{{ item.type }}</span>
                    <span>NO.{{item.id}}</span>
                </div>
                <div class="buts">
                    <div>
                        <p>{{ $t('lp.money') }}: {{item.amount}} USDT</p>
                        <p>{{ $t('lp.startDate') }}: {{item.time}}</p>
                        <p>{{ $t('lp.endDate') }}: {{item.endTime}}</p>
                    </div>
                    <button v-if="item.canRedeem" @click="redeem(item.serial)">{{ $t('lp.redeem') }}</button>
                </div>
            </div>
            <span class="no-data" v-if="stakes.totalRecord - 0 <= 0">
				<img src="../../static/images/no-data.png" />
			</span>
            <!-- 更多 -->
            <span class="more" v-if="stakes.page < stakes.totalPage" @click="getNextStakeList">{{ $t('award.more') }}</span>
        </div>
        <p class="copyright">© 2023 ID LABS. All rights reserved.</p>
    </div>
</template>
<script setup>
    import headers from '@/components/headers'
    import { useRouter, useRoute } from 'vue-router';

	import i18n from "@/i18n"
	import { ElMessage } from 'element-plus'

	import {memberStore} from "@/store/member"
	import {walletStore} from "@/store/wallet"
	import {webStore} from "@/store/web"
	import {computed, onMounted, reactive, ref, watch} from "vue"

	import {queryStakeListV2, redeemV2} from '@/api/stake'
	import Util from "@/utils/common-util";
	import {end, start} from "@/api/loading";
	import {send} from "@/contract/web3-util";

	const mStore = memberStore()
	const wStore = walletStore()
	const wbStore = webStore()

    const menuIndex = ref(2)
	const route = useRoute()
    const router = useRouter()

    const getMenu = (index) => {
        menuIndex.value = index
		getStakeList()
    }

    const goLp = () => {
        router.push({
            name: 'Lp'
        })
    }

	const stakes = reactive({
		page: 1,
		pageSize: 0,
		totalPage: 0,
		totalRecord: 0,
		list: [],
	})

	function getStakeList() {
		if (!wbStore.isLogin()) {
			return
		}

		stakes.list = []
		stakes.page = 1
		stakes.pageSize = 0
		stakes.totalPage = 0
		stakes.totalRecord = 0
		getStakes()
	}
	function getNextStakeList() {
		if (!wbStore.isLogin()) {
			return
		}

		if (stakes.page < stakes.totalPage) {
			stakes.page++
		} else {
			return
		}

		getStakes()
	}

	function getStakes() {
		if (!wbStore.isLogin()) {
			return
		}

		queryStakeListV2({page: stakes.page, pageSize: 6, type: menuIndex.value}).then(function (result) {
			if (Util.isDefine(result) && result.data.code === 1) {
				const data = result.data.data.pagingList
				stakes.page = data.currPage
				stakes.pageSize = data.pageSize
				stakes.totalPage = data.totalPage
				stakes.totalRecord = data.totalRecord

				const list = data.resultList
				for (let i = 0; Util.isDefine(list) && i < list.length; i++) {
					const a = list[i]
					const stake = {}
					stake.token = 'USDT'
					stake.amount = Util.numFormat4Floor(a.amount - 0)
					stake.id = a.id
					stake.serial = a.serial
					stake.canRedeem = false
					let endTime = 0
					if (a.type === 1) {
						stake.type = 'DEFI AI 0 – USDT'
						stake.canRedeem = true
					} else if (a.type === 2) {
						stake.type = 'DEFI AI 30 – USDT'
						if (new Date().getTime() / 1000 - a.time > 30 * 24 * 3600) {
							stake.canRedeem = true
						}
						endTime = a.time + 30 * 24 * 3600
					} else if (a.type === 3) {
						stake.type = 'DEFI AI 90 – USDT'
						if (new Date().getTime() / 1000 - a.time > 90 * 24 * 3600) {
							stake.canRedeem = true
						}
						endTime = a.time + 90 * 24 * 3600
					}
					const time = Util.timestampToDate(a.time)
					stake.time = new Intl.DateTimeFormat('en-US', { dateStyle: 'long'}).format(new Date(a.time * 1000)) + ' ' + time.time
					if (endTime > 0) {
						const eTime = Util.timestampToDate(endTime)
						stake.endTime = new Intl.DateTimeFormat('en-US', { dateStyle: 'long'}).format(new Date(endTime * 1000)) + ' ' + eTime.time
					} else {
						stake.endTime = '-'
					}

					stakes.list.push(stake)
				}
			}
		})
	}

	const redeem = (serial) => {
		if (!wbStore.isLogin()) {
			ElMessage({
				message: i18n.global.t('alert.connectWallet'),
				type: 'warning'
			})
			return
		}

		start()
		redeemV2({orderSerial: serial}).then(result => {
			if (Util.isDefine(result) && result.data.code === 1) {
				const data = result.data.data

				const orderSerial = data.orderSerial
				const signTime = data.signTime
				const sign = data.sign

				for (let i = 0 ; i < stakes.list.length ; i++) {
					if (serial === stakes.list[i].serial) {
						stakes.list[i].canRedeem = false
					}
				}

				send('stake-v2', 'redeem', [orderSerial, signTime, sign])
					.then((receipt) => {
						if (receipt.status) {
							ElMessage({
								message: i18n.global.t('alert.success'),
								type: 'success'
							})

						} else {
							ElMessage({
								message: i18n.global.t('alert.failed'),
								type: 'error'
							})
						}
						end()
					}).catch(function(error) {
					console.error(error)
					end()
				})
			}
		})

	}


	onMounted(() => {
		const type = route.query.type
		if (type - 0 > 0 && type - 0 < 4) {
			menuIndex.value = type
		}

		getStakeList()
	})

	const curAccount = computed(()=>{
		return wStore.curAccount
	})
	watch(curAccount, (newVal, oldVal) =>{
		if (newVal) {
			if (oldVal) {
				alert(i18n.global.t('alert.reLogin'))
			}

			wbStore.accountLogin().then((result) => {
				wbStore.setLoginState(true)
				mStore.queryMemberInfo()

				getStakeList()
			}).catch((reason) => {
				wbStore.setLoginState(false)
				console.error('login failed')
			})
		}
	})

</script>
<style scoped>
    .root{
        color: white;
        padding-bottom: 20px;
        background: linear-gradient(90deg, #172929 0%, #0D1113 50%, #0E152B 100%);
    }
    .get-lay{
        width: calc(100% - 320px);
        margin: 100px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .get-lay .left p{
        width: 400px;
        font-size: 12px;
        color: #BECFD2;
    }
    .get-lay img{
        width: 50%;
    }
    .record-title{
        width: calc(100% - 320px);
        margin: 0 auto;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    .record-title img{
        width: 20px;
        transform: rotateY(180deg);
    }
    .record-img{
        transform: rotateY(0) !important;
    }
    .record-title div{
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .record-title > span{
        font-size: 14px;
        font-weight: bold;
        background: linear-gradient(to left, #40FFF4 0%, #FFFFFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        border-bottom: 3px solid #20EEA4;
        padding-bottom: 10px;
    }
    .lp-list{
        width: 100%;
        padding: 100px 0;
        background-image: url(../../static/images/Launching-pad-bg-lp.png);
        background-repeat: no-repeat;
        background-size:  cover;
    }
    .lp-list > div{
        width: calc(100% - 320px);
        max-width: 1200px;
        margin: 0 auto;
        padding: 0;
        display: flex;
        justify-content: space-between;
    }
    .lp-list > div > div{
        width: 30%;
        background-color: #005474;
        border-radius: 24px;
    }
    .lp-list .info{
        display: flex;
        align-items: center;
        padding: 24px
    }
    .lp-list .info img{
        width: 96px;
        margin-right: 16px;
    }
    .lp-list .info div{
        height: 96px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        font-size: 16px;
    }
    .lp-list .info div span:first-child + span{
        color: #20EEA4;
        font-size: 14px;
        white-space: nowrap;
    }
    .lp-list .info div span:last-child{
        color: rgba(255,255,255,0.75);;
        background: rgba(255,255,255,0.15);
        border-radius: 99px 99px 99px 99px;
        text-align: center;
        padding: 5px 20px;
        font-size: 12px;
    }
    .lp-list .information{
        background-color: white;
        border-radius: 24px;
        padding: 24px;
    }
    .lp-list .information .apr{
        background-color: rgba(0,84,116,0.15);
        border-radius: 8px;
        color: #005474;
        padding: 8px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
    }
    .lp-list .information .apr span:first-child{
        font-size: 12px;
        font-weight: normal;
    }
    .lp-list .information .row{
        color: rgba(13,17,19,0.75);
        display: flex;
        flex-direction: column;
        font-size: 12px;
        margin-top: 24px;
    }
    .lp-list .information .row span:last-child{
        font-weight: bold;
        margin-top: 5px;
    }
    .lp-list .information .row-lay{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;
    }
    .lp-list .information .row-lay .row{
        margin-top: 0;
    }
    .lp-list .information .row-lay button{
        font-size: 12px;
        width: 70px;
        height: 30px;
        background-color: #29D8FF !important;
        border-radius: 4px;
        font-weight: bold;
    }
    .lp-list .information .buts{
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
    }
    .lp-list .information button{
        width: calc(50% - 10px);
        height: 40px;
        background-color: #20EEA4;
        border-radius: 8px;
        font-weight: bold;
    }
    .lp-list .information button:last-child{
        background-color: #D6D6D6;
    }
    .value .title{
        width: 100%;
        font-size: 16px;
        text-align: center;
        margin: 100px 0 70px;
        background: linear-gradient(299deg, #20EEA4 0%, #FFFFFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
    }
    .value img{
        width: 80px;
    }
    .value .content {
        max-width: 1082px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .value .content div{
        width: calc(50% - 168px);
        margin-bottom: 30px;
        background-color: #242829;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px 74px 32px;
        border-radius: 8px;
        font-size: 12px;
        color: #BECFD2;
    }
    .value .content .name{
        color: #20EEA4;
        font-size: 14px;
        margin: 10px 0;
        text-align: center;
    }
    .list{
        background-color: #1C282C;
        padding: 56px 160px;
    }
    .list .content{
        max-width: calc(1200px - 48px);
        margin: 0 auto 24px;
        padding: 24px;
        border-radius: 12px;
        color: black;
        background-color: #E9FFF7;
        font-size: 12px;
    }
    .list .id{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: rgba(13,17,19,0.5);
    }
    .list .id span:first-child{
        color: #005474;
    }
    .list .buts{
        color: rgba(13,17,19,0.75);
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    .list .buts p:first-child{
        font-weight: bold;
        font-size: 14px;
    }
    .list .buts p:last-child{
        margin-bottom: 0
    }
    .list .buts button{
        width: 120px;
        height: 39px;
        border-radius: 6px;
        margin-top: 10px;
        font-weight: bold;
        background-color: #20EEA4;
    }
    .menu{
        display: flex;
        align-items: center;
        font-size: 12px;
        margin: 0 auto 40px;
    }
    .menu > span{
        margin-right: 20px;
        cursor: pointer;
        padding: 5px 10px;
    }
    .get-menu{
        color: #20EEA4;
        background: rgba(32,238,164,0.1);
        border-radius: 8px 8px 8px 8px;
        border: 1px solid rgba(32,238,164,0.35);
    }
    .dialog{
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, .7);
        position: fixed;
        z-index: 1010;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dialog > div{
        width: calc(100% - 100px);
        max-width: 300px;
        padding: 24px;
        background: #E9FFF7;
        box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.1);
        border-radius: 24px 24px 24px 24px;
        color: black;
        display: flex;
        flex-direction: column;
    }
    .dialog img{
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
    .dialog .title{
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .dialog input{
        height: 40px;
        padding: 0 8px;
        border-radius: 8px;
        border: 1px solid #CCCCCC;
    }
    .dialog button{
        height: 40px;
        font-weight: bold;
        background-color: #20EEA4;
        border-radius: 8px;
        margin-top: 40px;
    }
    .dialog .usable{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 40px 0 20px;
    }
    .dialog .usable div{
        display: flex;
        align-items: center;
        font-size: 12px;
    }
    .dialog .usable div img{
        width: 25px;
        height: 25px;
        margin-right: 5px;
    }
    .dialog .usable .max{
        color: black;
        background-color: #29D8FF;
        padding: 1px 5px;
        border-radius: 4px;
        margin-left: 8px;
    }
    .dialog .accredit{
        background-color: #29D8FF;
    }
    .dialog .disabled{
        background-color: #D6D6D6;
    }
    @media screen and (max-width: 900px) {
        .root{
            padding-bottom: 70px
        }
        .lp-list{
            padding: 40px 0;
        }
        .lp-list > div{
            width: 100%;
            flex-direction: column;
        }
        .lp-list > div > div{
            width: calc(100% - 48px);
            margin: 0 auto 40px;
        }
        .lp-list > div > div:last-child{
            margin-bottom: 0;
        }
        .pc{
            display: none;
        }
        .get-lay{
            width: calc(100% - 64px);
            margin: 30px auto 48px;
            flex-direction: column;
            align-items: flex-start;
        }
        .get-lay .left p{
            width: 100%;
            margin-bottom: 20px;
        }
        .get-lay img{
            width: 100%;
        }
        .record-title{
            width: calc(100% - 64px);
        }
        .list{
            padding: 30px 24px;
        }
        .list .buts{
            flex-direction: column;
        }
        .list button{
            width: 100% !important;
            margin-top: 20px !important;
        }
        .menu > span{
            white-space: nowrap;
            margin-right: 5px;
        }
        .value .content div .name{
            margin: 20px 0;
        }
        .value .content div{
            width: calc(100% - 110px);
            padding: 32px;
            margin: 0 auto 24px
        }
        .lp-list .info img{
            width: 80px;
            margin-right: 16px;
        }
        .lp-list .info div{
            height: 80px;
        }
    }
</style>
